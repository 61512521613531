import { useFormContext } from '@bbc-account/id-formaxe';
import { Button } from '@bbc-account/id-components';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { errorShape } from '../../../propTypes';
import Track from '../../shared/analytics/track';
import FormError from '../../shared/formError';
import { EmailFirstPageLayout } from '../../email-first';
import {
    StyledEmailFirstPageLayoutContent,
    StyledForm,
} from './emailFirstForm.style';
import { useEmailFirstFormTracking } from './useEmailFirstFormTracking';
import { EmailFirstSignInCta } from './cta';
import { MagicLinkCopy } from '../../auth/pages/magicLinkCopy';

export const EmailFirstForm = ({
    activeFields,
    children,
    formError,
    heading,
    hideBackButton,
    name,
    onBackButtonClick,
    onSubmit,
    onSubmitInvalid,
    pageId,
    secondaryContent,
    selectedCountry,
    submitButtonId,
    submitButtonLabel,
    withSignInCta,
    withMagicLinkCta,
}) => {
    const { trackSubmitMetadata } = useEmailFirstFormTracking({
        selectedCountry,
    });

    const { handleSubmit, fieldValues } = useFormContext();

    const formErrorWrapperRef = useRef();

    useEffect(() => {
        if (formError) {
            const {
                y: formErrorY,
            } = formErrorWrapperRef.current.getBoundingClientRect();

            const { scrollY } = window;

            const yOffset = 50;

            const y = formErrorY + scrollY - yOffset;

            if (formErrorY < yOffset) {
                window.scrollTo({ behavior: 'smooth', top: y });
            }
        }
    }, [formError]);

    return (
        <EmailFirstPageLayout
            heading={heading}
            hideBackButton={hideBackButton}
            onBackButtonClick={onBackButtonClick}
            pageId={pageId}
            secondaryContent={secondaryContent}
        >
            <StyledEmailFirstPageLayoutContent>
                <div ref={formErrorWrapperRef}>
                    <FormError
                        error={formError}
                        isAssertive
                        migrateToIdComponents
                        name={name}
                    />
                </div>
                <StyledForm
                    data-testid="form"
                    noValidate
                    onSubmit={handleSubmit(onSubmit, onSubmitInvalid, {
                        activeFields,
                    })}
                >
                    {children}
                    <Track metadata={trackSubmitMetadata} ignoreViews>
                        <Button id={submitButtonId} isFullWidth isSubmit>
                            <FormattedMessage id={submitButtonLabel} />
                        </Button>
                    </Track>
                </StyledForm>
                {withSignInCta && <EmailFirstSignInCta />}
                {withMagicLinkCta && (
                    <MagicLinkCopy email={fieldValues.username} />
                )}
            </StyledEmailFirstPageLayoutContent>
        </EmailFirstPageLayout>
    );
};

EmailFirstForm.propTypes = {
    activeFields: PropTypes.arrayOf(PropTypes.string),
    children: PropTypes.node,
    formError: errorShape,
    heading: EmailFirstPageLayout.propTypes.heading,
    hideBackButton: EmailFirstPageLayout.propTypes.hideBackButton,
    name: PropTypes.string,
    onBackButtonClick: EmailFirstPageLayout.propTypes.onBackButtonClick,
    onSubmit: PropTypes.func,
    onSubmitInvalid: PropTypes.func,
    pageId: EmailFirstPageLayout.propTypes.pageId,
    secondaryContent: EmailFirstPageLayout.propTypes.secondaryContent,
    selectedCountry: PropTypes.string,
    submitButtonId: Button.propTypes.id,
    submitButtonLabel: PropTypes.string,
    withSignInCta: PropTypes.bool,
    withMagicLinkCta: PropTypes.bool,
};

EmailFirstForm.defaultProps = {
    submitButtonId: 'submit-button',
    submitButtonLabel: 'register.button.submit.value',
};
