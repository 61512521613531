export const basePath = '/auth/register';

export const emailFirstRoutes = {
    details: {
        path: `${basePath}/details`,
        title: `emailFirst.page.title.register.details`,
    },
    email: {
        path: `${basePath}/email`,
        title: `emailFirst.page.title.register.email`,
    },
    password: {
        path: `${basePath}/password`,
        title: `emailFirst.page.title.register.password`,
    },
};

export const baseFederatedPath = '/auth/register/federated';

export const emailFirstFederatedRoutes = {
    details: {
        path: `${baseFederatedPath}/details`,
        title: `emailFirst.page.title.register.details`,
    },
    email: {
        path: `${baseFederatedPath}/email`,
        title: `emailFirst.page.title.register.email`,
    },
    password: {
        path: `${baseFederatedPath}/password`,
        title: `emailFirst.page.title.register.password`,
    },
};

export const baseSingleEntryPath = '/auth/identifier';

export const singleEntryRoutes = {
    email: {
        path: baseSingleEntryPath,
        title: `emailFirst.page.title.single-entry`,
    },
    login: {
        path: `${baseSingleEntryPath}/login`,
        title: `emailFirst.page.title.login`,
    },
    details: {
        path: `${baseSingleEntryPath}/register/details`,
        title: `emailFirst.page.title.register.details`,
    },
    password: {
        path: `${baseSingleEntryPath}/register/password`,
        title: `emailFirst.page.title.register.password`,
    },
};

export const getEmailFirstRoutes = (isFederated, isSingleEntryAuth) => {
    if (isFederated) {
        return {
            emailFirstPaths: emailFirstFederatedRoutes,
            emailFirstBasePath: baseFederatedPath,
        };
    }

    if (isSingleEntryAuth) {
        return {
            emailFirstPaths: singleEntryRoutes,
            emailFirstBasePath: baseSingleEntryPath,
        };
    }

    return {
        emailFirstPaths: emailFirstRoutes,
        emailFirstBasePath: basePath,
    };
};
