import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
    EMAIL_FIRST_EMAIL_FORM_INPUT_NAME,
    EMAIL_FIRST_PASSWORD_FORM_INPUT_NAME,
    EmailFirstPasswordFormInput,
} from '../inputs';
import { useStateContext } from '../../../../modules/stateContext';
import { usePageBeforeUnloadEventDispatchContext } from '../../../../pages/events';
import { useEmailFirstForm } from '../useEmailFirstForm';
import signIn from '../../../../services/signIn';
import { PAGE_BEFORE_UNLOAD_EVENT_CONTEXT_REDUCER_ACTIONS } from '../../../../pages/events/beforeUnload/pageBeforeUnloadEventContextReducer';
import { EmailFirstForm } from '../emailFirstForm';
import { getEmailFirstRoutes } from '../../../../pages/email-first/emailFirstRouteNames';

const activeFields = [EMAIL_FIRST_PASSWORD_FORM_INPUT_NAME];

export const EmailFirstLoginForm = () => {
    const [formError, setFormError] = useState();
    const userContext = useStateContext();
    const dispatchPageBeforeUnloadEvent = usePageBeforeUnloadEventDispatchContext();
    const { emailFirstPaths } = getEmailFirstRoutes(
        userContext.isFederated,
        userContext.isSingleEntryAuth
    );

    const {
        fieldValues,
        handleBack,
        handleSubmit,
        handleSubmitInvalid,
    } = useEmailFirstForm({
        activeFields,
        prevRoute: emailFirstPaths.email.path,
        preSubmit: async () => {
            const {
                sessionUrl: newSessionUrl,
                message: signinErrorMessage,
            } = await signIn(
                fieldValues[EMAIL_FIRST_EMAIL_FORM_INPUT_NAME],
                fieldValues[EMAIL_FIRST_PASSWORD_FORM_INPUT_NAME],
                userContext
            );

            if (newSessionUrl) {
                window.location.assign(newSessionUrl);
            } else {
                setFormError(signinErrorMessage);
            }

            return false;
        },
    });

    useEffect(() => {
        dispatchPageBeforeUnloadEvent({
            type:
                PAGE_BEFORE_UNLOAD_EVENT_CONTEXT_REDUCER_ACTIONS.DISABLE_BEFORE_UNLOAD_EVENT_LISTENERS,
        });
    }, []);

    return (
        <EmailFirstForm
            activeFields={activeFields}
            formError={formError}
            heading={<FormattedMessage id="auth.password.title.introduction" />}
            onBackButtonClick={handleBack}
            onSubmit={handleSubmit}
            submitButtonLabel="emailFirst.identifier.login"
            onSubmitInvalid={handleSubmitInvalid}
            withMagicLinkCta={userContext.featureToggles.magicLinks}
        >
            <EmailFirstPasswordFormInput
                defaultValue={fieldValues && fieldValues.password}
                shouldValidate
                showChecklist={false}
                checkPasswordBreach={false}
            />
        </EmailFirstForm>
    );
};
