import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import {
    EmailFirstEmailForm,
    EmailFirstPasswordForm,
    EmailFirstDetailsForm,
    EmailFirstDetailsFormExUk,
} from '../../../components/forms/email-first';
import { useShowExUkContent } from '../../../hooks/useShowExUkContent';
import { getEmailFirstRoutes } from '../emailFirstRouteNames';
import { useEmailFirstRoutes } from './useEmailFirstRoutes';
import { useStateContext } from '../../../modules/stateContext';
import { EmailFirstLoginForm } from '../../../components/forms/email-first/login/emailFirstLoginForm';

export const EmailFirstRoutes = () => {
    const { isFederated, isSingleEntryAuth } = useStateContext();
    const { search } = useEmailFirstRoutes(isSingleEntryAuth);

    const showExUkContent = useShowExUkContent();

    const { emailFirstPaths, emailFirstBasePath } = getEmailFirstRoutes(
        isFederated,
        isSingleEntryAuth
    );

    const DetailsForm = showExUkContent
        ? EmailFirstDetailsFormExUk
        : EmailFirstDetailsForm;

    const emailPath = emailFirstPaths.email.path;
    const passwordPath = emailFirstPaths.password.path;
    const detailsPath = emailFirstPaths.details.path;
    const rootPath = emailFirstBasePath;

    return (
        <Switch>
            <Route exact path={emailPath} component={EmailFirstEmailForm} />
            <Route
                exact
                path={passwordPath}
                component={EmailFirstPasswordForm}
            />
            {isSingleEntryAuth && (
                <Route
                    exact
                    path={emailFirstPaths.login.path}
                    component={EmailFirstLoginForm}
                />
            )}
            <Route exact path={detailsPath} component={DetailsForm} />
            <Route path={`${rootPath}/*`}>
                <Redirect to={`${emailFirstPaths.email.path}${search}`} />
            </Route>
        </Switch>
    );
};
